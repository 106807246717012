export const links = {
  test: {
    openPageOne: (id: string) => `/test/${id}/1`,
  },

  //     “about: "/about",
  //   contact: "/contact-us",
  //   articles: {
  //     list: "/articles",
  //     single: (id) => `/articles/${id}`,
  //   },”
};
